import { Injectable } from '@angular/core'
import { LbUtilsService } from 'lb-utils-front/dist'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { Observable, of } from 'rxjs'
import * as _ from 'lodash'
import { first, switchMap } from 'rxjs/operators'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { Endpoint } from 'lb-types'

const MAX_QUEUES_ENDPOINTS_TO_LOAD = 15

@Injectable({
    providedIn: 'root'
})
export class EndpointsService {

    private queuesEndpoints: { [queueId: string]: { [endpointId: string]: Endpoint } } = {}
    private endpointsConfigurations: { [endpointId: string]: any } = {}
    private listOfQueuesIdLoaded: string[] = []
    private listOfEndpointsIdLoaded: string[] = []

    constructor(
        private lbUtilsService: LbUtilsService,
        private httpService: HttpService,
        private configService: ConfigService
    ) { }

    public getEndpoints ( queuesId: string[] ): Observable<{ [queueId: string]: { [endpointId: string]: Endpoint } }> {
        const queuesIdToLoad = []
        for ( const queueId of queuesId ) {
            if ( !this.queuesEndpoints || !this.queuesEndpoints[ queueId ] ) {
                queuesIdToLoad.push( queueId )
            }
        }

        let ob: any = of( {} )
        if ( queuesIdToLoad && queuesIdToLoad.length > 0 ) {
            ob = this.httpService.get(
                this.configService.httpUrl.endpoints.getEndpoints, {queuesId: queuesIdToLoad}, null
            ).pipe(
                switchMap( ( res: { [queueId: string]: { [endpointId: string]: Endpoint } } ) => {
                    for ( const tmpQueueId in res) {
                        this.queuesEndpoints[ tmpQueueId ] = res[ tmpQueueId ]
                        this.listOfQueuesIdLoaded.push( tmpQueueId )
                        if ( this.listOfQueuesIdLoaded.length > MAX_QUEUES_ENDPOINTS_TO_LOAD ) {
                            delete this.queuesEndpoints[ this.listOfQueuesIdLoaded[ 0 ] ]
                            this.listOfQueuesIdLoaded.shift()
                        }
                    }
                    return of( res )
                } ),
                first()
            )
        }

        return ob.pipe(
            switchMap( () => {
                const res = {}
                for ( const queueId of queuesId ) {
                    res[ queueId ] = _.cloneDeep(this.queuesEndpoints[ queueId ])
                }
                return of( res )
            })
        )
    }

    public getEndpointById( queueId: string | null, endpointId: string ) {
        if ( queueId ) {
            if ( this.queuesEndpoints && this.queuesEndpoints[ queueId ] && this.queuesEndpoints[ queueId ][ endpointId ] ) {
                return _.cloneDeep( this.queuesEndpoints[ queueId ][ endpointId ] )
            } else {
                return null
            }
        } else {
            for ( const tmpQueueId in this.queuesEndpoints ) {
                if ( this.queuesEndpoints[tmpQueueId][endpointId] ) {
                    return _.cloneDeep( this.queuesEndpoints[ tmpQueueId ][ endpointId ] )
                }
            }
            return null
        }
    }

    public getEndpointOpeningHistory( endpointsId: string[], start?: string, end?: string ): Observable<{ [endpointId: string]: { status: string, time: string }[] }> {
        return this.httpService.get(
            this.configService.httpUrl.endpoints.getEndpointsOpeningHistory, { endpointsId: endpointsId, startAt: start, endAt: end }, null
        ).pipe(
            switchMap( ( res: { [endpointId: string]: { status: string, time: string }[] } ) => {
                return of( res )
            }),
            first()
        )
    }

    public createEndpoints( params: Partial<Endpoint>[] ): Observable<LocalCRUDRes> {
        if ( Object.keys( params ).length > 0 ) {
            return this.httpService.post(
                this.configService.httpUrl.endpoints.createEndpoints, { endpoints: params }, null, null
            ).pipe(
                switchMap( ( httpRes: HttpCRUDRes ) => {
                    for ( const endpointId in httpRes.objectSuccess ) {
                        if ( endpointId in httpRes.objectSuccess && httpRes.objectSuccess[ endpointId ].queueId ) {
                            if (!this.queuesEndpoints[ httpRes.objectSuccess[ endpointId ].queueId ]) this.queuesEndpoints[ httpRes.objectSuccess[ endpointId ].queueId ] = {}
                            this.queuesEndpoints[ httpRes.objectSuccess[ endpointId ].queueId ][endpointId] = httpRes.objectSuccess[ endpointId ]
                        }
                    }

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true, res: httpRes.objectSuccess } )
                    } else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                    }
                })
            )
        } else {
            return of( { success: true } )
        }
    }

    public deleteEndpoints( queueId: string, endpointsIds: string[] ): Observable<LocalCRUDRes> {
        if ( Object.keys( endpointsIds ).length > 0 ) {
            return this.httpService.delete(
                this.configService.httpUrl.endpoints.deleteEndpoints, { endpointsIds: endpointsIds }, null, null
            ).pipe(
                switchMap( ( httpRes: HttpCRUDRes ) => {
                    for ( const endpointId in httpRes.objectSuccess ) {
                        if ( endpointId in httpRes.objectSuccess ) {
                            delete this.queuesEndpoints[queueId][endpointId]
                        }
                    }

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true } )
                    } else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                    }
                })
            )
        } else {
            return of( { success: true } )
        }
    }

    public editEndpoints ( params: { [queueId: string]: { endpointId: string, endpointSet: Partial<Endpoint> }[] } ): Observable<LocalCRUDRes> {
        const set = {}

        for ( const queueId in params ) {
            if ( Object.keys( params[ queueId ] ).length > 0 ) {
                for ( const param of params[ queueId ] ) {
                    const finalEndpointsSetSet: any = this.lbUtilsService.diffObject( param.endpointSet, this.queuesEndpoints[ queueId ][ param.endpointId ] )
                    set[ param.endpointId ] = finalEndpointsSetSet
                }
            }
        }

        if ( Object.keys( set ).length > 0 ) {
            return this.httpService.put(
                this.configService.httpUrl.endpoints.setEndpoints, { endpoints: set }, null, null
            ).pipe(
                switchMap( ( httpRes: HttpCRUDRes ) => {
                    for ( const endpointId in httpRes.objectSuccess ) {
                        if ( endpointId in httpRes.objectSuccess && httpRes.objectSuccess[ endpointId ].queueId ) {
                            this.queuesEndpoints[ httpRes.objectSuccess[ endpointId ].queueId ][ endpointId ] = httpRes.objectSuccess[ endpointId ]

                        }
                    }

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true } )
                    } else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                    }
                })
            )
        } else {
            return of( { success: true } )
        }
    }

    public getEndpointsConfigurations ( endpointsId: string[] ): Observable<{ [endpointId: string]: any }> {
        const endpointsIdToLoad = []
        for ( const endpointId of endpointsId ) {
            if ( !this.endpointsConfigurations || !this.endpointsConfigurations[ endpointId ] ) {
                endpointsIdToLoad.push( endpointId )
            }
        }

        let ob: any = of( {} )
        if ( endpointsIdToLoad && endpointsIdToLoad.length > 0 ) {
            ob = this.httpService.get(
                this.configService.httpUrl.endpoints.getEndpointsConfigurations, {endpointsId: endpointsIdToLoad}, null
            ).pipe(
                switchMap( ( res: { [endpointId: string]: any} ) => {
                    for ( const tmpEndpointId in res) {
                        this.endpointsConfigurations[ tmpEndpointId ] = res[ tmpEndpointId ]
                        this.listOfEndpointsIdLoaded.push( tmpEndpointId )
                        if ( this.listOfEndpointsIdLoaded.length > MAX_QUEUES_ENDPOINTS_TO_LOAD ) {
                            delete this.endpointsConfigurations[ this.listOfEndpointsIdLoaded[ 0 ] ]
                            this.listOfEndpointsIdLoaded.shift()
                        }
                    }
                    return of( res )
                } ),
                first()
            )
        }

        return ob.pipe(
            switchMap( () => {
                const res = {}
                for ( const endpointId of endpointsId ) {
                    res[ endpointId ] = _.cloneDeep(this.endpointsConfigurations[ endpointId ])
                }
                return of( res )
            })
        )
    }

    public getEndpointConfigurationById( endpointId: string ): Observable<any> {
        if ( this.endpointsConfigurations && this.endpointsConfigurations[ endpointId ] ) {
            return of( _.cloneDeep( this.endpointsConfigurations[ endpointId ] ) )
        } else {
            return this.getEndpointsConfigurations( [endpointId] ).pipe(
                switchMap(
                    ( res ) => {
                        return of(res[endpointId])
                    }
                )
            )
        }
    }

    public createEndpointsConfigurations( params: { [endpointId: string]: any[] } ): Observable<LocalCRUDRes> {
        if ( Object.keys( params ).length > 0 ) {
            return this.httpService.post(
                this.configService.httpUrl.endpoints.createEndpointsConfigurations, { endpointsId: params }, null, null
            ).pipe(
                switchMap( ( httpRes: HttpCRUDRes ) => {
                    for ( const endpointId in httpRes.objectSuccess ) {
                        if ( endpointId in httpRes.objectSuccess ) {
                            this.endpointsConfigurations[endpointId] = httpRes.objectSuccess[ endpointId ]
                        }
                    }

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true } )
                    } else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                    }
                })
            )
        } else {
            return of( { success: true } )
        }
    }

    public deleteEndpointsConfigurations( endpointsId: string[] ): Observable<LocalCRUDRes> {
        if ( Object.keys( endpointsId ).length > 0 ) {
            return this.httpService.delete(
                this.configService.httpUrl.endpoints.deleteEndpoints, { endpointsId: endpointsId }, null, null
            ).pipe(
                switchMap( ( httpRes: HttpCRUDRes ) => {
                    for ( const endpointId in httpRes.objectSuccess ) {
                        if ( endpointId in httpRes.objectSuccess ) {
                            delete this.queuesEndpoints[endpointId]
                        }
                    }

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true } )
                    } else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                    }
                })
            )
        } else {
            return of( { success: true } )
        }
    }

    public editEndpointsConfigurations ( params: { [endpointId: string]: any } ): Observable<LocalCRUDRes> {
        return this.httpService.put(
            this.configService.httpUrl.endpoints.setEndpointsConfigurations, { endpoints: params }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const endpointId in httpRes.objectSuccess ) {
                    if ( endpointId in httpRes.objectSuccess ) {
                        this.endpointsConfigurations[ endpointId ] = httpRes.objectSuccess[ endpointId ]
                    }
                }

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true } )
                } else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                }
            })
        )
    }

}
