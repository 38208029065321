import { Injectable } from '@angular/core'
import { NbAuthJWTToken, NbAuthResult, NbAuthService, NbTokenService } from '@nebular/auth'
import { catchError, first, map, switchMap, tap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { Router } from '@angular/router'
import { LbUtilsService } from 'lb-utils-front/dist'
import * as ms from 'ms'
import { HttpService } from './utils/http.service'
import { ConfigService } from './config.service'
import { CompaniesPlacesQueuesService } from './default-resources/companies-places-queues.service'
import { WindowService } from './utils/window.service'
import {OperatorDto} from "lb-types/dist";
import {HttpCRUDRes, LocalCRUDRes} from "../interfaces/http/http";

const REFRESH_TOKEN_VALUE = ms('5m')

export type RoleAndAccessRoles = {roles: string[], allAccessRoles?: AllAccessRolesFromUrl }
export type AccessRoles = {ressource: string, availableRoles?: string[], queuePlaceOrCompanyId?: string | null} | null
export type AllAccessRolesFromUrl = {ressource: string, availableRoles?: string[], queuePlaceOrCompanyId?: string | null}[] | null

@Injectable( {
    providedIn: 'root'
} )
export class UserService {

    private userConnected: boolean = false
    private timeoutRefreshToken = null
    private operatorAccount = null
    private operatorProfile = null
    private operatorAdminRole = []
    public minStepResources = {
        developerAccount: 'queue',
        operators: 'global',
        profiles: 'global',
        snapshots: 'global',
        cache: 'global',
        extendedAttributesTypeGlobal: 'global',
        extendedAttributesTypeCompany: 'company',
        companies: 'company',
        places: 'place',
        placesTimeslots: 'place',
        subPlaces: 'place',
        categories: 'place',
        queues: 'queue',
        tickets: 'queue',
        endpoints: 'queue',
        endpointsTimeslots: 'queue',
        queueBookingTimeslots: 'queue',
        bookingApplications: 'place',
        validationApplications: 'queue',
        displayApplications: 'place',
        mobileAppsApplications: 'place',
        reportings: 'queue',
        exports: 'global',
        triggers: 'queue',
        sms: 'queue',
        appNotifications: 'queue',
        emails: 'queue',
        queueConfig: 'queue',
    }

    private displayPref: {
        showImages: true
    }

    constructor (
        private lbUtilsService: LbUtilsService,
        private authService: NbAuthService,
        private nbTokenService: NbTokenService,
        private httpService: HttpService,
        private configService: ConfigService,
        private companiesPlacesQueuesService: CompaniesPlacesQueuesService,
        private windowService: WindowService,
        private router: Router
    ) {
        this.isAuthenticated()
            .subscribe( ( authenticated: boolean ): void => {
                this.userConnected = authenticated
            })
    }

    public getOperatorAccount(): any {
        if ( typeof this.operatorAccount !== 'undefined' ) {
            return this.operatorAccount
        } else {
            return null
        }
    }

    public getCurrentOperatorId() {
        const ob = this.authService.getToken()
        return ob.pipe(
            switchMap((res) => {
                return of(res.getPayload().operatorId)
            }),
            first()
        )
    }
  
    public getDefaultDevAccountId(): any {
        if(this.operatorProfile.profiles !== null && this.operatorProfile.profiles !== undefined) {
            if(Object.keys(this.operatorProfile.profiles).length > 0){
                for (const profile in this.operatorProfile.profiles) {
                    if (this.operatorProfile.profiles[profile].type === 'AdminResources') {
                        return this.operatorProfile.profiles[profile].devAccountId
                    }
                }
            }
        }
        return null
    }

    public getOperatorRoles(): any {
        if ( typeof this.operatorProfile !== 'undefined' ) {
            return this.operatorProfile
        } else {

        }
    }

    public getOperatorAdminRoles(): any {
        if ( typeof this.operatorAdminRole !== 'undefined' ) {
            return this.operatorAdminRole
        } else {
            return null
        }
    }

    public operatorHasAccessFromObjectAndUrl( roles: string[] | null, allAccessRoles?: AllAccessRolesFromUrl, queuePlaceOrCompanyId?: string | null ) {
        let hasAccess = false
        const url = this.windowService.getUrl()

        if ( allAccessRoles ) {
            for ( const k in allAccessRoles ) {
                const accessRoles: AccessRoles = allAccessRoles[k]
                if ( !queuePlaceOrCompanyId && accessRoles && accessRoles.ressource && this.minStepResources[ accessRoles.ressource ] !== 'global' ) {
                    const minStep = this.minStepResources[ accessRoles.ressource ]
                    // queueId
                    if ( url && url.match( /.+\/queues\/([a-zA-Z0-9\-\_]+)(\/.+)*$/ ) ) {
                        let queueId = url.match( /.+\/queues\/([a-zA-Z0-9\-\_]+)(\/.+)*$/ )[1]
                        const queue = this.companiesPlacesQueuesService.getQueueById( queueId )
                        if ( minStep === 'queue' ) { accessRoles.queuePlaceOrCompanyId = queueId }
                        if ( queue ) {
                            if ( minStep === 'place' ) { accessRoles.queuePlaceOrCompanyId = queue.placeId }
                            if ( minStep === 'company' ) { accessRoles.queuePlaceOrCompanyId = queue.companyId }
                        }

                        // placeId
                    } else if ( url && url.match( /.+\/places\/([a-zA-Z0-9\-\_]+)\/[a-zA-Z]+/ ) ) {
                        let placeId = url.match( /.+\/places\/([a-zA-Z0-9\-\_]+)(\/.+)*$/ )[1]
                        const place = this.companiesPlacesQueuesService.getPlaceById( placeId )
                        if ( minStep === 'place' ) { accessRoles.queuePlaceOrCompanyId = placeId }
                        if ( place ) {
                            if ( minStep === 'company' ) { accessRoles.queuePlaceOrCompanyId = place.companyId }
                        }

                        // companyId
                    } else if ( url && url.match( /.+\/companies\/([a-zA-Z0-9\-\_]+)\/[a-zA-Z]+/ ) ) {
                        let companyId = url.match( /.+\/companies\/([a-zA-Z0-9\-\_]+)(\/.+)*$/ )[1]
                        if ( minStep === 'company' ) { accessRoles.queuePlaceOrCompanyId = companyId }
                    }
                } else if ( queuePlaceOrCompanyId ) {
                    accessRoles.queuePlaceOrCompanyId = queuePlaceOrCompanyId
                }
                const tmpHasAccess = this.operatorHasAccess( roles, accessRoles )
                hasAccess = hasAccess || tmpHasAccess
                if ( hasAccess ) { break }
            }
        } else {
            hasAccess = this.operatorHasAccess( roles, null )
        }
        return hasAccess
    }

    public operatorHasAccess( valideRoles: string[] | null, accessRoles?: AccessRoles ): boolean {
        const roles = this.getOperatorAdminRoles()
        let accessOk: boolean = false
        if ( valideRoles && valideRoles.length > 0 ) {
            for ( const role of valideRoles ) {
                if ( role === 'all' || roles.indexOf( role ) >= 0 ) {
                    accessOk = true
                    break
                }
            }
        } else {
            accessOk = true
        }
        if ( accessRoles && accessRoles.ressource && accessRoles.ressource.length > 0 && accessRoles.availableRoles && accessRoles.availableRoles.length > 0) {
            accessOk = this.getAdminAccess( accessRoles.ressource, accessRoles.availableRoles, accessRoles.queuePlaceOrCompanyId )
        }
        return accessOk
    }

    public getAdminAccess = ( ressource: string, availableRoles: string[], queuePlaceOrCompanyId: string | null ): boolean => {
        const profiles = this.operatorProfile
        const minStep = this.minStepResources[ ressource ]
        let companyId
        let placeId
        let queueId

        if ( !minStep ) {
            console.log('getAdminAccess() : ERROR : min step not defined for resource : ' + ressource)
            return false
        } else if ( minStep === 'company' && queuePlaceOrCompanyId ) {
            companyId = queuePlaceOrCompanyId
        } else if ( minStep === 'place' && queuePlaceOrCompanyId ) {
            const place = this.companiesPlacesQueuesService.getPlaceById( queuePlaceOrCompanyId )
            if ( place ) {
                companyId = place.companyId
                placeId = queuePlaceOrCompanyId
            }
        } else if ( minStep === 'queue' && queuePlaceOrCompanyId ) {
            const queue = this.companiesPlacesQueuesService.getQueueById( queuePlaceOrCompanyId )
            if ( queue ) {
                companyId = queue.companyId
                placeId = queue.placeId
                queueId = queuePlaceOrCompanyId
            }
        }

        if ( profiles.roles ) {
            if ( profiles.roles.all && profiles.roles.all.length > 0 ) {
                if ( this.adminAccessExist( profiles.roles.all, profiles.profiles, ressource, availableRoles ) ) { return true }
            }
            if ( profiles.roles.byCompanies ) {
                for ( const companyData of profiles.roles.byCompanies ) {
                    if ( companyData.companyId === companyId || !companyId ) {
                        if ( this.adminAccessExist( companyData.all, profiles.profiles, ressource, availableRoles ) ) { return true }
                        for ( const placeData of companyData.byPlaces ) {
                            if ( placeData.placeId === placeId || !placeId ) {
                                const place = this.companiesPlacesQueuesService.getPlaceById(placeData.placeId)
                                if (place) {
                                    const tmpCompanyId = place.companyId
                                
                                    if ( 
                                        (minStep === 'place' && placeId && placeData.placeId === placeId)
                                        || (minStep === 'company' && tmpCompanyId && tmpCompanyId === companyId)
                                    ) {
                                    
                                        if ( this.adminAccessExist( placeData.all, profiles.profiles, ressource, availableRoles ) ) { return true }
                                    }
                                    for ( const queueData of placeData.byQueues ) {
                                    
                                        if ( queueData.queueId === queueId || !queueId ) {
                                           
                                            const queue = this.companiesPlacesQueuesService.getQueueById(queuePlaceOrCompanyId)
                                        

                                            if (this.adminAccessExist(queueData.profiles, profiles.profiles, ressource, availableRoles)) { 
                                                return true 
                                            }

                                            if (queue) {
                                                const tmpPlaceId = queue.placeId

                                                if (
                                                    (minStep === 'queue' && queueId && queueData.queueId === queueId)
                                                    || (minStep === 'place' && placeId && tmpPlaceId === placeId)
                                                    || (minStep === 'company' && tmpCompanyId && tmpCompanyId === companyId)
                                                ) {
                                                    if (this.adminAccessExist(queueData.profiles, profiles.profiles, ressource, availableRoles)) { 
                                                        return true 
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return false
    }

    private adminAccessExist = ( profilesArray, profilesList, ressource, wantedRoles ) => {
        for ( const profileId of profilesArray ) {
            if ( profilesList && profilesList[ profileId ] && profilesList[ profileId ].type === 'AdminResources' ) {
                const currentRoles = profilesList[ profileId ].adminResources && profilesList[ profileId ].adminResources[ ressource ] && profilesList[ profileId ].adminResources[ ressource ].length > 0 ? profilesList[ profileId ].adminResources[ ressource ] : []
                for ( const role of wantedRoles ) {
                    if ( currentRoles.indexOf( role ) >= 0 ) {
                        return true
                    }
                }
            }
        }
        return false
    }

    companyInCurrentOperatorProfiles(resource){
        const isDefinedAndNotEmpty = (array) => {
            return array !== undefined && array !== null && array.length > 0
        }
        let res = false 
        if(isDefinedAndNotEmpty(this.operatorProfile.roles.all))
          return true
        this.operatorProfile.roles.byCompanies.forEach((company) => {
          if(resource.companyId === company.companyId && isDefinedAndNotEmpty(company.all)){
            res = true
            return
          }
        })
        return res
      }

      placeInCurrentOperatorProfiles(resource){
        let res = false
        const isDefinedAndNotEmpty = (array) => {
            return array !== undefined && array !== null && array.length > 0
        }
        if(isDefinedAndNotEmpty(this.operatorProfile.roles.all))
          return true
        this.operatorProfile.roles.byCompanies.forEach((company) => {
          if(company.companyId === resource.companyId && isDefinedAndNotEmpty(company.all)){
            res = true
            return
          }
          company.byPlaces.forEach((place) => {
            if(resource.placeId === place.placeId && isDefinedAndNotEmpty(place.all)){
              res = true
              return
            }
          })
        })
        return res
      }
    
      queueInCurrentOperatorProfiles(resource){
        let res = false
        const isDefinedAndNotEmpty = (array) => {
            return array !== undefined && array !== null && array.length > 0
        }
        if(isDefinedAndNotEmpty(this.operatorProfile.roles.all))
          return true
        this.operatorProfile.roles.byCompanies.forEach((company) => {
          if(company.companyId === resource.companyId && isDefinedAndNotEmpty(company.all)){
            res = true
            return
          }
          company.byPlaces.forEach((place) => {
            if(place.placeId === resource.placeId && isDefinedAndNotEmpty(place.all)){
              res = true
              return
            }
            place.byQueues.forEach((queue) => {
              if(resource.queueId === queue.queueId){
                res = true
                return
              }
            })
          })
        })
        return res
      }

    public setPref( name: string, value: any ): void {
        if ( typeof this.displayPref[ name ] !== 'undefined' ) {
            this.displayPref[ name ] = value
        }
    }

    public getPref( name: string ): any {
        if ( typeof this.displayPref[ name ] !== 'undefined' ) {
            return this.displayPref[ name ]
        } else {
            return null
        }
    }

    private loadOperatorData(): Observable<any> {
        if ( this.userConnected ) {
            let ob
            if ( !this.operatorAccount ) {
                ob = this.httpGetOperatorAccount()
            } else {
                ob = of( true )
            }

            return ob.pipe(
                switchMap( () => {
                    if ( !this.operatorProfile ) {
                        return this.httpGetOperatorRoles()
                    } else {
                        return of( true )
                    }
                })
            )
        } else {
            this.operatorAccount = null
            this.operatorProfile = null
            return of( true )
        }
    }

    public setUserConnected (isConnected: boolean) {
        this.userConnected = isConnected
        return this.loadOperatorData()
    }

    httpGetOperatorAccount () : Observable<boolean> {
        if ( this.userConnected ) {
            return this.httpService.get(
                this.configService.httpUrl.account.getOperatorAccount, null, null
            ).pipe(
                switchMap( (account: any) => {
                    this.operatorAccount = account
                    return of( true )
                }),
                first(),
                catchError( err => {
                    console.log('error while getting operator infos')
                    console.log( err )
                    this.operatorAccount = null
                    return of(false)
                })
            )
        } else {
            return of( true )
        }
    }

    httpGetOperatorRoles () : Observable<boolean> {
        this.operatorAdminRole = []
        if ( this.userConnected ) {
            return this.httpService.get(
                this.configService.httpUrl.account.getOperatorRoles, null, null
            ).pipe(
                switchMap( (roles: any) => {
                    this.operatorProfile = roles

                    if ( this.operatorProfile && this.operatorProfile.profiles && this.operatorProfile.roles && this.operatorProfile.roles.all ) {
                        for ( const profileId of this.operatorProfile.roles.all ) {
                            if (
                                this.operatorProfile.profiles[ profileId ]
                                && this.operatorProfile.profiles[ profileId ].type === 'AdminLinebertyVisual'
                                && this.operatorProfile.profiles[ profileId ].adminVisual
                                && this.operatorProfile.profiles[ profileId ].adminVisual.roles
                            ) {
                                for ( const role of this.operatorProfile.profiles[ profileId ].adminVisual.roles ) {
                                    if ( this.operatorAdminRole.indexOf( role ) < 0 ) {
                                        this.operatorAdminRole.push( role )
                                    }
                                }
                            }
                        }
                    }

                    if (this.operatorProfile?.profiles && this.operatorProfile?.roles?.byCompanies) {
                        for (const companyRole of this.operatorProfile.roles.byCompanies) {
                            if (companyRole.all) {
                                companyRole.all.forEach(profileId => {
                                    if (this.operatorProfile.profiles[profileId]?.type === 'AdminLinebertyVisual'
                                        && this.operatorProfile.profiles[profileId]?.adminVisual?.roles) {
                                        this.operatorProfile.profiles[profileId].adminVisual.roles.forEach(role => {
                                            if (this.operatorAdminRole.indexOf(role) < 0) {
                                                this.operatorAdminRole.push(role)
                                            }
                                        })
                                    }
                                })
                            }

                            if (companyRole.byPlaces) {
                                for (const placeRole of companyRole.byPlaces) {
                                    if (placeRole.all) {
                                        placeRole.all.forEach(profileId => {
                                            if (this.operatorProfile.profiles[profileId]?.type === 'AdminLinebertyVisual'
                                                && this.operatorProfile.profiles[profileId]?.adminVisual?.roles) {
                                                this.operatorProfile.profiles[profileId].adminVisual.roles.forEach(role => {
                                                    if (this.operatorAdminRole.indexOf(role) < 0) {
                                                        this.operatorAdminRole.push(role)
                                                    }
                                                })
                                            }
                                        })
                                    }

                                    if (placeRole.byQueues) {
                                        for (const queueRole of placeRole.byQueues) {
                                            if (queueRole.profiles) {
                                                queueRole.profiles.forEach(profileId => {
                                                    if (this.operatorProfile.profiles[profileId]?.type === 'AdminLinebertyVisual'
                                                        && this.operatorProfile.profiles[profileId]?.adminVisual?.roles) {
                                                        this.operatorProfile.profiles[profileId].adminVisual.roles.forEach(role => {
                                                            if (this.operatorAdminRole.indexOf(role) < 0) {
                                                                this.operatorAdminRole.push(role)
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    return of( true )
                }),
                first(),
                catchError( err => {
                    console.log('error while getting operator roles')
                    console.log( err )
                    this.operatorProfile = null
                    return of(false)
                })
            )
        } else {
            return of( true )
        }
    }

    initTimer( token ) {
        const self: any = this
        const exp = token.exp * 1000
        if ( exp > Date.now() && exp - Date.now() <= REFRESH_TOKEN_VALUE ) {
            this.refreshToken( token ).subscribe( ( res: boolean ) => {
                this.authService.refreshToken('loginId', null).subscribe( ( res: NbAuthResult ) => {
                    let delay
                    if ( res ) {
                        console.log('Token refreshed')
                        delay = 0
                    } else {
                        console.log('Error while refreshing token')
                        delay = ms('30s')
                    }
                    clearTimeout( this.timeoutRefreshToken )
                    this.timeoutRefreshToken = setTimeout( () => {
                        this.authService.getToken().pipe(
                            map( ( token: NbAuthJWTToken ) => {
                                this.initTimer( token.getPayload() )
                            } )
                        )
                    }, delay)
                })
            })
        } else if ( !this.timeoutRefreshToken ) {
            if ( exp > Date.now() && exp - Date.now() > REFRESH_TOKEN_VALUE ) {
                this.timeoutRefreshToken = setTimeout( () => {
                    self.authService.getToken().subscribe( (token: NbAuthJWTToken) => {
                        self.nbRefresh = 1
                        // self.initTimer( token.getAccessTokenPayload() )
                    })
                }, ( exp - REFRESH_TOKEN_VALUE - Date.now() ))
            }
        }
    }

    refreshToken( payload ): Observable<boolean> {
        console.log('refreshToken')
        console.log(payload)
        return this.authService.refreshToken( 'loginId' ).pipe(
            switchMap( ( res: NbAuthResult): Observable<boolean> => {
                return of( res.isSuccess() )
            })
        )
        /*return this.httpService.get(
            this.configService.httpUrl.token.refreshToken, null, null
        ).pipe(
            switchMap( (tokenRes: any) => {
                console.log('refresh ok')
                console.log(tokenRes)
                this.nbTokenService.set( tokenRes.jwtToken )
                return of( true )
            }),
            first(),
            catchError( err => {
                console.log('error while refreshing token')
                console.log( err )
                return of(false)
            })
        )*/
    }

    isAuthenticated (): Observable<boolean> {
        return this.authService.getToken()
            .pipe(
                switchMap( ( token: NbAuthJWTToken ) => {
                    let authenticated = false
                    if ( token ) {
                        if ( token.isValid() ) {
                            const payload = token.getPayload()
                            this.initTimer( payload )
                            if ( payload && payload.exp && (payload.exp * 1000) > Date.now() ) {
                                authenticated = true
                            }
                        } else {
                            if ( this.timeoutRefreshToken ) {
                                clearTimeout( this.timeoutRefreshToken )
                                this.timeoutRefreshToken = null
                            }
                        }
                    }

                    this.userConnected = authenticated
                    return this.loadOperatorData().pipe(
                        switchMap( () => {
                            return of( authenticated )
                        })
                    )
                } )
            )
    }

    onFocus() {
        const before = this.userConnected
        this.isAuthenticated()
            .subscribe( ( authenticated: boolean ): void => {
                if ( !authenticated && before) {
                    this.router.navigate( ['/auth/before-logout'] )
                }
            })
    }

    public editAccount ( accountSet ): Observable<boolean> {
        const account = this.getOperatorAccount()
        const accountResSet: any = this.lbUtilsService.diffObject( accountSet, account )

        return this.httpService.put(
            this.configService.httpUrl.account.editOperatorAccount,
            { account: accountResSet }, null, null
        ).pipe(
            switchMap( ( httpRes: any ) => {
                if ( httpRes ) {
                    this.operatorAccount = httpRes
                }
                return of(true)
            })
        )
    }

    public editPassword ( oldPassword, newPassword ): Observable<boolean> {
        return this.httpService.put(
            this.configService.httpUrl.account.editOperatorPassword,
            { oldPassword, newPassword }, null, null
        ).pipe(
            switchMap( ( httpRes: any ) => {
                if ( httpRes && httpRes.res ) {
                    return of(true)
                } else {
                    return of(false)
                }
            })
        )
    }

    public forgotPassword (loginId: string): Observable<boolean> {
        return this.httpService.post(
            this.configService.httpUrl.account.forgotPassword,
            { loginId : loginId }, null, null
        ).pipe(
            switchMap( ( httpRes: any ) => {
                if ( httpRes && httpRes.res ) {
                    return of(true)
                } else {
                    return of(false)
                }
            })
        )
    }

    public editEmail ( password, email ): Observable<boolean> {
        return this.httpService.put(
            this.configService.httpUrl.account.editOperatorEmail,
            { password, email }, null, null
        ).pipe(
            switchMap( ( httpRes: any ) => {
                if ( httpRes ) {
                    this.operatorAccount = httpRes
                }
                return of(true)
            })
        )
    }

    public deleteAccount () : Observable<boolean> {
        return this.httpService.delete(
            this.configService.httpUrl.account.deleteOperatorAccount,
            null, null, null
        ).pipe(
            switchMap( ( httpRes: any ) => {
                if ( httpRes ) {
                    return of(true)
                } else {

                    return of(false)
                }
            })
        )
    }
}
