import { Component, Inject, Input, OnInit } from '@angular/core'
import { Langs } from 'lb-types'
import { NbDialogRef } from '@nebular/theme'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { LbTranslationService } from 'lb-utils-front/dist'

@Component({
  selector: 'ngx-select-multi',
  templateUrl: './select-multi.component.html',
  styleUrls: ['./select-multi.component.scss']
})
export class SelectMultiComponent implements OnInit {

  @Input() possibilities: { value: any, langs?: Langs | string, name?: Langs | string }[]
  @Input() selected: any[]
  @Input() title: string
  public filter: string = ''

  constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private lbTranslationService: LbTranslationService,
      protected ref: NbDialogRef<SelectMultiComponent>
  ) { }

  ngOnInit (): void {
    if ( !this.selected ) {
      this.selected = []
    }
  }

  cancel() {
    this.ref.close()
  }

  isSelected( value ): boolean {
    return this.selected.indexOf( value ) >= 0
  }

  match ( possibility: { value: any, langs?: Langs, text?: string } ) {
    const text = this.lbTranslationService.translateFromObject( possibility.langs, {lang: this.locale.language} )
    return text.toUpperCase().match(this.filter.toUpperCase())
  }

  select( value ): void {
    if ( this.selected.indexOf( value ) >= 0 ) {
      this.selected.splice( this.selected.indexOf( value ), 1 )
    } else {
      this.selected.push( value )
    }
  }

  submit(): void {
    this.ref.close( this.selected )
  }

}
