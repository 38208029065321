import { Injectable } from '@angular/core'
import { LbUtilsService } from 'lb-utils-front/dist'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { AppAdminQueueConfiguration, AppAdminQueueConfigurationAdminMobileApps, AppBookingQueueConfiguration, AppTimetableQueueConfiguration } from 'lb-types/dist'
import { Observable, of } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'
import * as _ from 'lodash'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { CacheService } from '../utils/cache.service'

const MAX_APP_BOOKING_QUEUES_CONFIG_TO_LOAD = 15
const MAX_APP_TIMETABLE_QUEUES_CONFIG_TO_LOAD = 15
const MAX_APP_ADMIN_QUEUES_CONFIG_TO_LOAD = 15

export type LabelMakerType = 'v1' | 'v2' | 'letter_by_appointmentTypes' | 'letter_by_extendedAttributes' | 'editedByDeveloper'

@Injectable({
    providedIn: 'root'
})
export class QueuesConfigurationService {

    private appBookingQueuesConfig: { [queueId: string]: AppBookingQueueConfiguration } = {}
    private listOfAppBookingQueuesIdLoaded: string[] = []
    private appTimetableQueuesConfig: { [queueId: string]: AppTimetableQueueConfiguration } = {}
    private listOfTimetableQueuesIdLoaded: string[] = []
    private appAdminQueuesConfig: { [queueId: string]: AppAdminQueueConfiguration } = {}
    private listOfAdminQueuesIdLoaded: string[] = []

    constructor(
        private cacheService: CacheService,
        private lbUtilsService: LbUtilsService,
        private httpService: HttpService,
        private configService: ConfigService
    ) { }

    getTicketDuration( aps: any, pMin: number, pMax: number ): { min: null | number, max: null | number } {
        const res = { min: null, max: null }
        if ( !pMin ) { pMin = 1 }
        if ( !pMax ) { pMax = pMin }
        for ( const k in aps) {
            const tmpMin = aps[k].duration + ( aps[k].durationPerPerson * pMin )
            const tmpMax = aps[k].duration + ( aps[k].durationPerPerson * pMax )
            if ( res.min === null || tmpMin < res.min ) { res.min = tmpMin }
            if ( res.max === null || tmpMax > res.max ) { res.max = tmpMax }
        }
        return res
    }

    public getAppBookingQueueConfigurations ( queuesId: string[] ): Observable<{[queueId: string]: AppBookingQueueConfiguration}> {
        const res: {[queueId: string]: AppBookingQueueConfiguration} = {}
        const queueIdToLoad = []
        for ( const queueId of queuesId ) {
            if ( this.appBookingQueuesConfig && this.appBookingQueuesConfig[ queueId ] ) {
                res[ queueId ] = _.cloneDeep(this.appBookingQueuesConfig[ queueId ])
            } else {
                queueIdToLoad.push( queueId )
            }
        }

        if ( queueIdToLoad.length > 0 ) {
            return this.httpService.get(
                this.configService.httpUrl.queues.getAppBookingQueuesConfiguration, {queuesId: queueIdToLoad}, null
            ).pipe(
                switchMap( ( httpRes: { [queueId: string]: AppBookingQueueConfiguration } ) => {
                    for ( const queueId in httpRes ) {
                        this.appBookingQueuesConfig[ queueId ] = httpRes[ queueId ]
                        res[ queueId ] = httpRes[ queueId ]
                        this.listOfAppBookingQueuesIdLoaded.push( queueId )
                        if ( this.listOfAppBookingQueuesIdLoaded.length > MAX_APP_BOOKING_QUEUES_CONFIG_TO_LOAD ) {
                            delete this.appBookingQueuesConfig[ this.listOfAppBookingQueuesIdLoaded[ 0 ] ]
                            this.listOfAppBookingQueuesIdLoaded.shift()
                        }
                    }
                    return of( res )
                } ),
                first()
            )
        } else {
            return of(res )
        }
    }

    public getAppBookingQueueConfiguration ( queueId: string ): Observable<AppBookingQueueConfiguration> {
        if ( this.appBookingQueuesConfig && this.appBookingQueuesConfig[ queueId ] ) {
            return of( _.cloneDeep(this.appBookingQueuesConfig[ queueId ]) )
        } else {
            return this.httpService.get(
                this.configService.httpUrl.queues.getAppBookingQueuesConfiguration, {queuesId: [queueId]}, null
            ).pipe(
                switchMap( ( res: { [queueId: string]: AppBookingQueueConfiguration } ) => {
                    this.appBookingQueuesConfig[ queueId ] = res[ queueId ]
                    this.listOfAppBookingQueuesIdLoaded.push( queueId )
                    if ( this.listOfAppBookingQueuesIdLoaded.length > MAX_APP_BOOKING_QUEUES_CONFIG_TO_LOAD ) {
                        delete this.appBookingQueuesConfig[ this.listOfAppBookingQueuesIdLoaded[ 0 ] ]
                        this.listOfAppBookingQueuesIdLoaded.shift()
                    }
                    return of( _.cloneDeep( this.appBookingQueuesConfig[ queueId ] ) )
                } ),
                first()
            )
        }
    }

    public getAppBookingQueueConfigurationLoggerLevel ( queueIds?: string ): Observable<any> {
        const query = {}
        if ( queueIds ) {
            query[ queueIds ] = queueIds
        }
        return this.httpService.get( this.configService.httpUrl.queues.getAppBookingQueuesConfigurationLogger, query, null )
    }

    public editAppBookingQueueConfiguration ( params: { queueId: string, queueConfigurationSet: Partial<AppBookingQueueConfiguration> }[] ): Observable<LocalCRUDRes> {
        const set = {}
        const queuesId = params.map( ( obj ) => { return obj.queueId } )

        return this.getAppBookingQueueConfigurations( queuesId ).pipe(
            switchMap( () => {
                for ( const param of params ) {
                    const queueConfigurationSet: any = this.lbUtilsService.diffObject( param.queueConfigurationSet, this.appBookingQueuesConfig[ param.queueId ] )
                    if ( typeof( queueConfigurationSet.appointmentTypes ) !== 'undefined' ) { queueConfigurationSet.appointmentTypes = param.queueConfigurationSet.appointmentTypes }
                    if ( typeof( queueConfigurationSet.labelGeneratorConfig ) !== 'undefined' ) { queueConfigurationSet.labelGeneratorConfig = param.queueConfigurationSet.labelGeneratorConfig }
                    if ( typeof( queueConfigurationSet.triggers ) !== 'undefined' ) { queueConfigurationSet.triggers = param.queueConfigurationSet.triggers }
                    if ( typeof( queueConfigurationSet.textToSpeech ) !== 'undefined' ) { queueConfigurationSet.textToSpeech = param.queueConfigurationSet.textToSpeech }
                    if ( typeof( queueConfigurationSet.extendedAttributes ) !== 'undefined' ) { queueConfigurationSet.extendedAttributes = param.queueConfigurationSet.extendedAttributes }
                    if ( typeof( queueConfigurationSet.nextQueueOnDone ) !== 'undefined' ) { queueConfigurationSet.nextQueueOnDone = param.queueConfigurationSet.nextQueueOnDone }
                    if ( typeof( queueConfigurationSet.authorizedSetState ) !== 'undefined' ) { queueConfigurationSet.authorizedSetState = param.queueConfigurationSet.authorizedSetState }
                    if ( typeof( queueConfigurationSet.hooks ) !== 'undefined' ) { queueConfigurationSet.hooks = param.queueConfigurationSet.hooks }
                    if ( typeof( queueConfigurationSet.generateIds) !== 'undefined') { queueConfigurationSet.generateIds = param.queueConfigurationSet.generateIds}
                    if ( Object.keys( queueConfigurationSet).length > 0 ) {
                        set[ param.queueId ] = queueConfigurationSet
                    }
                }

                if ( Object.keys( set).length > 0 ) {
                    return this.httpService.put(
                        this.configService.httpUrl.queues.setAppBookingQueuesConfiguration,
                        { queuesId: set }, null, null
                    ).pipe(
                        switchMap( ( httpRes: HttpCRUDRes ) => {
                            for ( const queueId in httpRes.objectSuccess ) {
                                if ( queueId in httpRes.objectSuccess ) {
                                    this.appBookingQueuesConfig[queueId] = _.cloneDeep( httpRes.objectSuccess[queueId] )
                                }
                            }

                            if ( httpRes.error === 0 && httpRes.success > 0 ) {
                                return of( { success: true, res: httpRes.objectSuccess } )
                            }
                            else {
                                return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                            }
                        })
                    )
                } else {
                    return of( { success: true } )
                }
            })
        )
    }

    public getAppTimetableQueueConfiguration ( queueId: string ): Observable<AppTimetableQueueConfiguration> {
        if ( this.appTimetableQueuesConfig && this.appTimetableQueuesConfig[ queueId ] ) {
            return of( this.appTimetableQueuesConfig[ queueId ] )
        } else {
            return this.httpService.get(
                this.configService.httpUrl.queues.getAppTimetableQueuesConfiguration, {queuesId: [queueId]}, null
            ).pipe(
                switchMap( ( res: { [queueId: string]: AppTimetableQueueConfiguration } ) => {
                    this.appTimetableQueuesConfig[ queueId ] = res[ queueId ]
                    this.listOfTimetableQueuesIdLoaded.push( queueId )
                    if ( this.listOfTimetableQueuesIdLoaded.length > MAX_APP_TIMETABLE_QUEUES_CONFIG_TO_LOAD ) {
                        delete this.appTimetableQueuesConfig[ this.listOfTimetableQueuesIdLoaded[ 0 ] ]
                        this.listOfTimetableQueuesIdLoaded.shift()
                    }
                    return of( _.cloneDeep( this.appTimetableQueuesConfig[ queueId ] ) )
                }),
                first()
            )
        }
    }

    public getAppAdminQueueConfiguration ( queueId: string ): Observable<AppAdminQueueConfiguration> {
        if ( this.appAdminQueuesConfig && this.appAdminQueuesConfig[ queueId ] ) {
            return of( this.appAdminQueuesConfig[ queueId ] )
        } else {
            return this.httpService.get(
                this.configService.httpUrl.queues.getAppAdminQueuesConfiguration, {queuesId: [queueId]}, null
            ).pipe(
                switchMap( ( res: { [queueId: string]: AppAdminQueueConfiguration } ) => {
                    this.appAdminQueuesConfig[ queueId ] = res[ queueId ]
                    this.listOfAdminQueuesIdLoaded.push( queueId )
                    if ( this.listOfAdminQueuesIdLoaded.length > MAX_APP_ADMIN_QUEUES_CONFIG_TO_LOAD ) {
                        delete this.appAdminQueuesConfig[ this.listOfAdminQueuesIdLoaded[ 0 ] ]
                        this.listOfAdminQueuesIdLoaded.shift()
                    }
                    return of( _.cloneDeep( this.appAdminQueuesConfig[ queueId ] ) )
                }),
                first()
            )
        }
    }

    public editAppTimetableQueueConfiguration ( params: { queueId: string, queueConfigurationSet: Partial<AppTimetableQueueConfiguration> }[] ): Observable<LocalCRUDRes> {
        const set = {}

        // TODO: load it if not loaded
        for ( const param of params ) {
            const queueConfigurationSet: any = this.lbUtilsService.diffObject( param.queueConfigurationSet, this.appTimetableQueuesConfig[ param.queueId ] )
            set[ param.queueId ] = queueConfigurationSet
        }

        return this.httpService.put(
            this.configService.httpUrl.queues.setAppTimetableQueuesConfiguration,
            { queuesId: set }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const queueId in httpRes.objectSuccess ) {
                    if ( queueId in httpRes.objectSuccess ) {
                        this.appTimetableQueuesConfig[queueId] = _.cloneDeep( httpRes.objectSuccess[queueId] )
                    }
                }

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true, res: httpRes.objectSuccess } )
                } else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                }
            })
        )
    }

    public editAppAdminQueueConfiguration ( params: { queueId: string, queueConfigurationSet: {mobileApps: Partial<AppAdminQueueConfigurationAdminMobileApps>} }[] ): Observable<LocalCRUDRes> {
        const set = {}
        let needToSet = false

        // TODO: load it if not loaded
        for ( const param of params ) {
            const queueConfigurationSet: any = this.lbUtilsService.diffObject( param.queueConfigurationSet, this.appAdminQueuesConfig[ param.queueId ] )
            set[ param.queueId ] = queueConfigurationSet
            if ( set[ param.queueId ] && set[ param.queueId ].mobileApps ) {
                needToSet = true
                if ( typeof ( set[ param.queueId ].mobileApps.extendedAttributes  )!== 'undefined' ) { set[ param.queueId ].mobileApps.extendedAttributes = param.queueConfigurationSet.mobileApps.extendedAttributes }
                if ( typeof ( set[ param.queueId ].mobileApps.ticketDisplay  )!== 'undefined' ) { set[ param.queueId ].mobileApps.ticketDisplay = param.queueConfigurationSet.mobileApps.ticketDisplay }
                if ( typeof ( set[ param.queueId ].mobileApps.bookTicket  )!== 'undefined' ) { set[ param.queueId ].mobileApps.bookTicket = param.queueConfigurationSet.mobileApps.bookTicket }
                if ( typeof ( set[ param.queueId ].mobileApps.selectQueue  )!== 'undefined' ) { set[ param.queueId ].mobileApps.selectQueue = param.queueConfigurationSet.mobileApps.selectQueue }
                if ( typeof ( set[ param.queueId ].mobileApps.showDescriptionPage  )!== 'undefined' ) { set[ param.queueId ].mobileApps.showDescriptionPage = param.queueConfigurationSet.mobileApps.showDescriptionPage }
            }
        }

        if ( needToSet ) {
            return this.httpService.put(
                this.configService.httpUrl.queues.setAppAdminQueuesConfiguration,
                { queuesConfigurations: set }, null, null
            ).pipe(
                switchMap( ( httpRes: HttpCRUDRes ) => {
                    for ( const queueId in httpRes.objectSuccess ) {
                        if ( queueId in httpRes.objectSuccess ) {
                            this.appAdminQueuesConfig[queueId] = _.cloneDeep( httpRes.objectSuccess[queueId] )
                        }
                    }

                    this.deleteMobileAppsCacheKeys( Object.keys( set ) )

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true } )
                    } else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                    }
                })
            )
        } else {
            return of( { success: true } )
        }
    }

    public createAppAdminQueueConfiguration ( params: { queueId: string, queueConfiguration: Partial<AppAdminQueueConfiguration> }[] ): Observable<LocalCRUDRes> {
        const body = {}
        for ( const param of params ) {
            body[ param.queueId ] = param.queueConfiguration
        }

        return this.httpService.post(
            this.configService.httpUrl.queues.createAppAdminQueuesConfiguration,
            { queuesConfigurations: body }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const queueId in httpRes.objectSuccess ) {
                    if ( queueId in httpRes.objectSuccess ) {
                        this.appAdminQueuesConfig[queueId] = _.cloneDeep( httpRes.objectSuccess[queueId] )
                    }
                }

                this.deleteMobileAppsCacheKeys( Object.keys( body ) )

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true } )
                } else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                }
            })
        )
    }

    public copyConfiguration( queueSourceId: string, queueDestId: string): Observable<LocalCRUDRes> {
        const body = {
            queueSourceId: queueSourceId,
            queueDestId: queueDestId
        }
        return this.httpService.post(
            this.configService.httpUrl.queues.copyQueueConfiguration,
            body, null, null
        ).pipe(
            switchMap((httpRes: HttpCRUDRes) => {
                if (httpRes.error === 0 && httpRes.success > 0) {
                    return of({success: true})
                } else {
                    return of({success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success)})
                }
            })
        )
    }

    public copyAppAdminQueueConfiguration( queueSourceId: string, queueDestId: string): Observable<LocalCRUDRes> {
        const body = {
            queueSourceId: queueSourceId,
            queueDestId: queueDestId
        }
        return this.httpService.post(
            this.configService.httpUrl.queues.copyAppAdminQueuesConfiguration,
            body, null, null
        ).pipe(
            switchMap((httpRes: HttpCRUDRes) => {
                if (httpRes.error === 0 && httpRes.success > 0) {
                    return of({success: true})
                } else {
                    return of({success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success)})
                }
            })
        )
    }


    public copyAppTimetableQueueConfiguration( queueSourceId: string, queueDestId: string): Observable<LocalCRUDRes> {
        const body = {
            queueSourceId: queueSourceId,
            queueDestId: queueDestId
        }
        return this.httpService.post(
            this.configService.httpUrl.queues.copyAppTimetableQueuesConfiguration,
            body, null, null
        ).pipe(
            switchMap((httpRes: HttpCRUDRes) => {
                if (httpRes.error === 0 && httpRes.success > 0) {
                    return of({success: true})
                } else {
                    return of({success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success)})
                }
            })
        )
    }

    public async deleteMobileAppsCacheKeys (queuesId: string[]): Promise<any> {
        const keys = [ '*appAdmin/queuesEncrypted/*/appConfigurations*' ]
        for ( const queueId of queuesId ) {
            keys.push('*appAdmin/queues/' + queueId + '/configuration*')
        }
        await this.cacheService.deleteKeys( keys, true ).toPromise()
    }

    public getLabelMakerType ( queueConfig: AppBookingQueueConfiguration ): LabelMakerType {
        let labelType: LabelMakerType = 'v1'
        if ( queueConfig ) {
            if ( !queueConfig.labelGeneratorVersion || queueConfig.labelGeneratorVersion === 1 ) {
                labelType = 'v1'
            } else if ( queueConfig.labelGeneratorVersion && queueConfig.labelGeneratorVersion === 2 ) {
                labelType = 'v2'
            } else if ( queueConfig.labelGeneratorVersion && queueConfig.labelGeneratorVersion === 3 && queueConfig.labelGeneratorConfig && queueConfig.labelGeneratorConfig.typeOfConfiguration === 'letter_by_appointmentTypes' ) {
                labelType = 'letter_by_appointmentTypes'
            } else if ( queueConfig.labelGeneratorVersion && queueConfig.labelGeneratorVersion === 3 && queueConfig.labelGeneratorConfig && queueConfig.labelGeneratorConfig.typeOfConfiguration === 'letter_by_extendedAttributes' ) {
                labelType = 'letter_by_extendedAttributes'
            } else if ( queueConfig.labelGeneratorVersion && queueConfig.labelGeneratorVersion === 3 && (!queueConfig.labelGeneratorConfig || queueConfig.labelGeneratorConfig.typeOfConfiguration === 'editedByDeveloper') ) {
                labelType = 'editedByDeveloper'
            } else {
                labelType = 'v1'
            }
        }
        return labelType
    }

    public reset() {
        this.appBookingQueuesConfig = {}
        this.listOfAppBookingQueuesIdLoaded = []
        this.appTimetableQueuesConfig = {}
        this.listOfTimetableQueuesIdLoaded = []
        this.appAdminQueuesConfig = {}
        this.listOfAdminQueuesIdLoaded = []
    }

}
