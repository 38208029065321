<ng-container *ngIf="!isOpened">
    <div class="translationContainer">
        <ng-container *ngIf="edit">
            <ng-container *ngIf="!markDown">
                <input *ngIf="data" type="text" nbInput fullWidth [(ngModel)]="data['fr_FR']" (change)="emit(data)" />
            </ng-container>
            <ng-container *ngIf="markDown">
                <ngx-markdown-editor *ngIf="data" [value]="data['fr_FR']" (onChange)="data['fr_FR'] = $event; emit(data)"></ngx-markdown-editor>
            </ng-container>
        </ng-container>
        <span *ngIf="!edit">
            <span *ngIf="!markDown">{{ data | lbTranslateFromObject:{lang: locale.language} }}</span>
            <span *ngIf="markDown" LbMarkdown >{{ data | lbTranslateFromObject:{lang: locale.language} }}</span>
        </span>
        <nb-icon (click)="isOpened = !isOpened" class="chevronIcon" icon="chevron-up" pack="font-awesome"></nb-icon>
    </div>
</ng-container>
<ng-container *ngIf="isOpened">
    <div class="translationContainer">
        <ngx-translation (onChange)="emit($event)" [size]="size" [markDown]="markDown" [constants]="constants" [showTextLength]="showTextLength" [showTitle]="showTitle" [data]="data" [edit]="edit" [showAddAndDelete]="showAddAndDelete" [checkValidity]="checkValidity" [validationNorm]="validationNorm"></ngx-translation>
        <nb-icon (click)="isOpened = !isOpened" class="chevronIcon withTranslation" icon="chevron-down" pack="font-awesome"></nb-icon>
    </div>
</ng-container>
